/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --primary: #1c3faa;
}
* {
  box-sizing: border-box;
  /* font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important; */
  /* font-family: "Raleway", sans-serif !important; */
  /* font-family: "Montserrat", sans-serif !important; */
  font-family: "Roboto", sans-serif !important;
}
html {
  font-size: 14px;
}
main {
  background: #f1f5f8;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
  .MuiTableSortLabel-icon {
  color: rgb(255 255 255 / 100%) !important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: rgb(255 255 255 / 100%) !important;
}
.MuiTableSortLabel-root:hover {
  color: rgb(255 255 255) !important;
}

.MuiTableBody-root [class*="RaButton-smallIcon"] {
  font-size: 16px !important;
}
.MuiTypography-body2 {
  font-size: 0.95rem !important;
}
.MuiList-root .MuiListItem-root > .MuiListItemIcon-root {
  min-width: 40px !important;
}
.MuiFilledInput-inputHiddenLabel.MuiFilledInput-inputMarginDense {
  padding-top: 7px;
  padding-bottom: 8px;
}
.MuiPaper-root {
  box-shadow: 0 3px 20px rgb(0 0 0 / 4%) !important;
}
.MuiTableCell-head:first-child {
  border-top-left-radius: 8px !important;
}
.MuiTableCell-head:last-child {
  border-top-right-radius: 8px !important;
}

.MuiPaper-rounded {
  border-radius: 8px !important;
}
.MuiButton-label [class*="RaButton-label"] {
  padding-left: 0.5em;
  font-weight: 500;
  font-size: 13px;
}
.MuiFormControl-root .MuiFilledInput-root {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%) !important;
  border: 1px solid #e2e8f0 !important;
  border-radius: 4px !important;
}
.MuiButton-root {
  padding: 8px 15px !important;
  box-shadow: none !important;
}
.MuiFilledInput-root:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}

[class*="RaImageField-image"] {
  margin: 0.2rem !important;
  max-height: 3rem !important;
}

[class*="jss94"] {
  margin: 0.2rem !important;
  max-height: 3rem !important;
}

.bannerImg img {
  margin: 0.2rem !important;
  max-height: 3rem !important;
}

[class*="jss93"] {
  margin: 0.2rem !important;
  max-height: 3rem !important;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.text-center {
  text-align: center;
}
.fw-400 {
  font-weight: 400;
}
.loginSection {
  height: calc(100vh - 27px);
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
}
.loginContainer .authLogo {
  text-align: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.loginContainer .authLogo img {
  max-height: 40px;
}
.loginContainer {
  padding: 1rem;
  /* border: 1px solid #f1f1f1; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 7px 0px #d2d2d2;
}
.changepasswordContainer {
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 7px 0px #d2d2d2;
}

.loginContainer .form-control {
  height: 40px;
  width: 300px;
  margin-bottom: 1rem;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  padding: 0 1rem;
}
.changepasswordContainer .form-control {
  height: 40px;
  width: 300px;
  margin-bottom: 1rem;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  padding: 0 1rem;
  display: block;
}
.btn {
  height: 32px;
  padding: 0 1rem;
  background-color: #fff;
  border: 1px solid var(--primary);
  border-radius: 4px;
  cursor: pointer;
}
.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}
.btn-primary:hover {
  opacity: 0.8;
}
.logo {
  margin-right: 1rem;
  order: -2;
  padding-left: 1rem;
  padding-top: 3px;
}
.logospace {
  min-width: 185px;
  display: flex;
  justify-content: center;
}
.logo img {
  height: 36px;
}
.powerlogo {
  order: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
.powerbylogo img {
  height: 26px;
  margin-left: 10px;
}
header.MuiAppBar-colorSecondary {
  background-color: #ffffff;
  color: #000;
}
.layout .MuiDrawer-root {
  background-color: var(--primary);
}
.layout .MuiDrawer-root[class*="RaSidebar-root"] .MuiMenuItem-root {
  color: #fff;
  padding-left: 16px;
}
main .MuiListItem-root .menuItemName,
main .MuiButtonBase-root.MuiListItem-button {
  color: #fff;
}
.MuiPopover-paper .MuiMenu-list .MuiListItem-button:hover {
  background-color: #1c3faa;
  color: #fff;
}

.MuiPopover-paper
  .MuiMenu-list
  .MuiListItem-button:hover
  .MuiListItemIcon-root {
  /* background-color: #1c3faa; */
  color: #fff;
}
.layout .MuiDrawer-root .MuiMenuItem-root .MuiListItemIcon-root {
  color: #ffffff8c;
}
.MuiTable-root .MuiTableHead-root .MuiTableCell-head {
  background-color: var(--primary);
  color: #fff;
}
.MuiTable-root .MuiTableHead-root .MuiTableCell-head .MuiCheckbox-root {
  /* background-color: var(--primary); */
  color: #fff;
}

.layout .RaLayout-content-4 {
  padding-left: 1.5rem;
}
.layout .jss4 {
  padding-left: 1.5rem;
}
.list-page .MuiToolbar-root {
  align-items: center;
  min-height: 0 !important;
}
.list-page .MuiToolbar-root .filter-field {
  align-items: center;
  position: relative;
}

.list-page .MuiToolbar-root .filter-field .MuiButtonBase-root {
  padding: 0;
  position: absolute;
  right: 17px;
  top: 1px;
  z-index: 1;
  font-size: 1rem;
}
.list-page .MuiToolbar-root .filter-field .MuiButtonBase-root .MuiSvgIcon-root {
  font-size: 1.2rem;
}
.MuiPaper-root[class*="RaList-bulkActionsDisplayed-"] .MuiToolbar-root {
  height: auto !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
.MuiToolbar-root .MuiToolbar-regular {
  padding-top: 0;
  padding-bottom: 0;
}
.MuiToolbar-root form {
  height: auto;
  min-height: 0;
  margin-top: 0;
}
.MuiToolbar-root form .MuiFormControl-root {
  margin-top: 0;
}

form.simple-form .MuiCardContent-root {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  max-width: 800px;
}
form.simple-form .MuiCardContent-root .ra-input {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: calc(100% / 2);
}
form.simple-form .MuiCardContent-root .ra-input .MuiFormControl-root {
  width: 100%;
}
form.simple-form
  .MuiCardContent-root
  .ra-input:is([class*="ra-input-organization_users"]) {
  max-width: 100% !important;
  width: 100%;
}
form.simple-form .ra-input-attachment_url {
  max-width: 100%;
  width: 100%;
}
form.simple-form .ra-input-attachment_url .MuiFormControl-root {
  width: 100%;
}
.create-packlist  .MuiCardContent-root .ra-input {   
  max-width: calc(100% / 1) !important;
}

.create-packlist.simple-form .MuiCardContent-root .ra-input .formSection .ra-input {   
  max-width: calc(100% / 2) !important; 
}

.create-packlist.simple-form .formSection {
  width: 100%;
  display: flex;
  flex: 2;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}
.create-packlist.simple-form .uploadImg {    display: flex;
  flex-direction: column;
  gap: 20px;
}


.create-packlist.simple-form .formSection>div {
  flex: 1 48% !important;
}

@media (max-width: 767px) {
  .create-packlist.simple-form .formSection>div {
    flex: 1 100% !important;
  }
  
  .create-packlist.simple-form .MuiCardContent-root .ra-input .formSection .ra-input {   
    max-width: 100% !important; 
  }
  
}

/* form.simple-form .MuiCardContent-root .ra-input .MuiFormControl-root {
  width: 100%;
} */
.MuiFormControl-root .MuiFilledInput-root {
  background-color: #fff;
  border: 1px solid #00000030;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  /* -o-bor8: ; */
  -o-border-radius: 8px;
}
.MuiFormControl-root .MuiFilledInput-underline:after {
  width: calc(100% - 10px);
  margin-left: 5px;
}
.MuiFormControl-root .MuiFilledInput-root:before {
  display: none;
}

.loader_blk_max {
  height: 100vh;
  width: 100vw;
}

.loader_blk {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader_blk img {
  height: 100px;
}

.RaTreeCustomMenuItem-menuItemName-43 {
  color: rgba(255, 255, 255, 1) !important;
}
.MuiList-root .MuiListItem-root > .MuiListItemIcon-root {
  min-width: 0;
}
.MuiList-root .MuiListItem-root .MuiListItemText-root {
  padding-left: 0;
}
.MuiDrawer-root[class*="RaSidebar-root"] .MuiList-root .MuiListItem-root {
  color: #fff;
}
/* .MuiDrawer-root[class*="RaSidebar-root"]
  .MuiList-root
  .MuiListItem-root[class*="RaMenuItemLink-active"],
.layout
  .MuiDrawer-root[class*="RaSidebar-root"]
  .MuiMenuItem-root[class*="RaMenuItemLink-active"].jss23 {
  background: red !important;
  border-radius: 15px;
  margin-right: 10px;
} */
.MuiList-root .MuiListItem-root .MuiListItemText-root .MuiTypography-root {
  font-weight: 500;
  white-space: nowrap;
}

.MuiList-root
  .MuiCollapse-container
  .MuiList-root
  .MuiButtonBase-root
  .MuiListItemIcon-root {
  margin-right: 12px;
  min-width: 21px;
}

.MuiTable-root .MuiTableCell-root td {
  font-size: 13px !important;
  font-weight: 500 !important;
}
.report-box {
  position: relative;
}
.report-box .box {
  padding: 20px;
  box-shadow: 0 3px 20px rgb(0 0 0 / 4%);
  position: relative;
  border-radius: 0.375rem;
  border-color: transparent;
  background-color: #fff;
  height: 130px;
}
.report-box .box .text-3xl {
  font-size: 40px;
  margin: 10px 0px 5px;
}
.report-box:before {
  content: "";
  width: 90%;
  background: #f9fafc;

  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  border-radius: 0.375rem;
}
.report-box .report-box__icon {
  color: rgba(49, 96, 216, 1);
}
.report-box .box .btn {
  height: auto;
  margin-top: 0.4rem;
  position: absolute;
  right: 20px;
  bottom: 1rem;
}
.report-box .box .custIcon {
  position: absolute;
  right: 20px;
  top: 18%;
  /* opacity: 0.5; */
  color: #fff;
  background: var(--primary);
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  padding: 15px;
}
.report-box .box .custIcon.custIcon-retailers {
  background: #5fa55a;
}
.report-box .box .custIcon.custIcon-brand {
  background: #01b4bc;
}
.report-box .box .custIcon.custIcon-products {
  background: #f6d51f;
}
.report-box .box .custIcon.custIcon-order {
  background: #fa8925;
}
.report-box .box .custIcon.custIcon-new-order {
  background: #fa5457;
}
.report-box .box .custIcon.custIcon-billing {
  background: #395112;
}
.report-box .box .custIcon.custIcon-dispatch {
  background: #84a02b;
}
.report-box .box .custIcon.custIcon-completed {
  background: #000;
}
.report-box .box .custIcon svg {
  width: 4.5rem;
  height: 4.5rem;
}
.report-box .box .text-3xl .custIcon {
  position: relative;
  left: 0;
  right: inherit;
  top: auto;
  font-size: 2rem;
  margin-right: 0.2rem;
  width: auto;
  height: auto;
  background: transparent;
  color: #000 !important;
  display: inline-block;
  padding: 0;
}
.report-box .box .text-3xl .custIcon svg {
  width: auto;
  height: auto;
}
@media (min-width: 1400px) {
  .report-box .box .custIcon svg {
    width: 4.5rem;
    height: 4.5rem;
    fill: #fff;
  }
}
.text-theme-11 {
  color: rgba(247, 139, 0, 1);
}
.text-theme-9 {
  color: rgba(145, 199, 20, 1);
}
.text-theme-10 {
  color: #c631d8 !important;
}
header .makeStyles-title-5 {
  display: none;
}
.btn {
  line-height: 1;
  font-style: normal;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}
.btn-sm {
  font-size: 0.8rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.btn-primary {
  margin-right: 10px;
  color: #fff;
  background-color: #1c3faa;
}
/* .dashboard {
  margin-top: -50px;
} */
.dashboard-flex {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.dashboard-flex input,
.dashboard-flex select {
  margin-left: auto;
  height: 38px;
  width: 100%;
  max-width: 200px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.MuiMenuItem-root.RaMenuItemLink-active-34 {
  background-color: rgba(255, 255, 255, 0.3);
}
.co-admin-create .MuiToolbar-regular {
  display: none !important;
}
.MuiTableBody-root .column-logo > div,
.MuiTableBody-root .column-logo > span {
  width: 60px;
  height: 50px;
  display: block;
}

.MuiTableBody-root .column-logo > div > img,
.MuiTableBody-root .column-logo > span > img {
  max-width: 100%;
  max-height: 100%;
}
.ra-input-logo input[name="logo"] + div > img,
.ra-input-logo input[name="logo"] + span > img {
  max-width: 200px;
  max-height: 80px;
}
.ra-input-sku > input {
  height: 47px;
  margin-top: 8px;
  width: 100%;
  padding-left: 0.8rem;
}
.chartContainer {
  padding-top: 1rem;
  margin-top: 1rem;
}
.chartContainer .MuiPaper-root {
  padding: 0 1rem 1rem;
}
.chartContainer .MuiPaper-root h3 {
  padding-bottom: 1rem;
  border-bottom: 1px solid #00000014;
}
.emptyChart {
  position: relative;
  display: block;
  float: left;
  width: 100%;
}
.emptyChart span {
  display: block;
  width: 100%;
  margin-top: 2.4rem;
  float: left;
  border-bottom: 1px solid #00000014;
}
.emptyChart span:not(:last-child) {
  margin-bottom: 2.4rem;
}
.noData {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.orderCode {
  text-align: center;
  margin: 0;
}
.MuiTableCell-sizeSmall {
  padding: 6px 24px 6px 16px;
}

/* invoice */
.showOrderContainer .invoice {
  max-width: 1080px;
  margin: 0 auto;
}
.orderTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.orderTitle .invoiceLogo {
  text-align: right;
}
.orderTitle .invoiceLogo img {
  height: 50px;
}
.orderTitle .invoiceLogo h4 {
  margin-top: 2px;
}
.invoiceTitle h1 {
  margin-top: 0;
  margin-bottom: 0.7rem;
}

.invoiceTitle .createDate {
  color: #b7b1b1;
  font-size: 1rem;
  font-weight: 400;
}
.invoiceTitle h1 .orderCode {
  font-weight: 400;
}

.allOrderAddress {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.allOrderAddress .billFrom {
  text-align: right;
}

.allOrderAddress .billFrom h4 {
  margin-top: 0;
  letter-spacing: 0.6px;
  margin-bottom: 4px;
  color: #b7b1b1;
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
}

.allOrderAddress .billFrom p {
  margin-top: 0;
  margin-bottom: 6px;
  font-weight: 700;
}

.allOrderAddress .billFrom address {
  font-weight: 300;
}

.allOrderAddress .billTo .billToText {
  color: #b7b1b1;
  font-weight: 600;
  font-size: 0.8rem;
  letter-spacing: 1px;
  margin-bottom: 4px;
}
.allOrderAddress .billTo .userDetail {
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.allOrderAddress .billTo .infoflex {
  line-height: 1.4;
  font-weight: 400;
}
.showOrderContainer table {
  box-shadow: none !important;
}
.showOrderContainer table .MuiTableCell-sizeSmall {
  padding: 0;
  min-height: 1rem;
  height: 2.3rem;
}
.showOrderContainer table thead th.MuiTableCell-sizeSmall {
  height: 2.3rem;
  color: #7e7e7e;
  font-weight: 500;
  letter-spacing: 0.7px;
  font-size: 0.8rem;
  text-transform: uppercase;
  border-bottom: 1px solid #b0b0b0;
  vertical-align: middle;
}
.showOrderContainer table tfoot th {
  border-top: 1px solid #b0b0b0;
  padding-top: 0.4rem;
  vertical-align: top;
  text-align: left;
}
.showOrderContainer table tfoot th strong {
  width: 100%;
  color: #b7b1b1;
  font-weight: 500;
  font-size: 0.8rem;
  letter-spacing: 1px;
  display: block;
  margin-bottom: 3px;
  text-transform: uppercase;
}
.showOrderContainer table tfoot th .paymentInfo span {
  font-weight: 400;
}
/* invoice */
/* # */
.retailerReportTable {
  border-radius: 8px 8px 0 0 !important;
  border-right: 0 !important;
  /* color: #fff; */
}
.retailerReportTable
  .MuiDataGrid-columnHeadersInner
  .MuiDataGrid-columnHeaderTitle {
  color: #fff;
}

.retailerReportTable .MuiDataGrid-columnHeaders {
  min-height: 30px !important;
  max-height: 30px !important;
  line-height: 30px !important;
  border-bottom: none;
  background-color: var(--primary);
  border-radius: 8px 8px 0 0 !important;
}
.MuiDataGrid-iconButtonContainer .MuiButtonBase-root {
  color: #fff;
}
.retailerReportTable .MuiDataGrid-virtualScroller .MuiDataGrid-row {
  cursor: pointer;
}
.MuiDataGrid-virtualScrollerContent .MuiDataGrid-virtualScrollerRenderZone {
  border: 1px solid rgba(224, 224, 224, 1);
}
.subscriptionCards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5%;
  height: 100%;
}
.subscriptionCards h1 {
  margin-top: 5%;
}
.changepassword {
  text-align: center;
  padding-bottom: inherit;
}
.closePopup {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

.editOrderId {
  text-align: center;
}
.editOrderId h1 {
  margin-top: 0;
}

.statusDropdown {
  display: flex;
  align-items: center;
}
.statusDropdown select {
  margin-left: auto;
  height: 38px;
  width: 100%;
  max-width: 200px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.text-right {
  text-align: right;
}
.invoiceLogo img {
  max-height: 50px;
}
.orderAddress h5 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.orderAddress p {
  margin: 0;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
}
.orderAddress p small {
  font-size: 12px;
}
.orderAddress p address {
  font-style: normal;
}
.invoicetitle {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  padding: 10px 0;
  margin: 15px 0;
  border-top: 1px solid #00000030;
  border-bottom: 1px solid #00000030;
}
.invoiceTable {
  margin-top: 20px;
}
.showOrderContainer table.invoiceTable thead th.MuiTableCell-sizeSmall {
  background-color: #eee;
  border-radius: 0 !important;
  padding: 6px 12px;
  color: #000;
  font-weight: 600;
}
.showOrderContainer table.invoiceTable tbody td {
  padding: 6px 12px;
  font-weight: 400;
}
.showOrderContainer table.invoiceTable tfoot th {
  text-transform: capitalize;
}
.invoiceOrderContainer {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}
.invoiceOrderContainer .invoice {
  background-color: #fff;
  padding: 30px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  max-width: 100%;
}
.invoiceButton {
  text-align: right;
  padding: 15px 0;
}
.no-box-shadow {
  box-shadow: none !important;
}
.upgradeModal .MuiBox-root {
  max-width: 1200px;
  padding: 0;
}
.pricingbox {
  background: #fff;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 15px;
  overflow: hidden;
}

.priceTitle {
  background: #303f9f;
  text-align: center;
  color: #fff;
  padding: 15px;
}
.priceBody {
  padding: 20px;
}
.priceBody li {
  font-size: 14px;
  font-weight: 400;
}
.priceBody ul {
  margin: 0;
  padding-left: 17px;
}
.pricetxt {
  font-weight: 600;
  text-align: center;
  color: #303f9f;
  padding-bottom: 14px;
  font-size: 24px;
}
.maintitle {
  background-color: #303f9f;
  font-size: 26px;
  color: #fff;
  padding: 15px;
  line-height: 1.2;
  text-align: center;
}
.popupBody {
  padding: 35px 25px;
}
.priceButton {
  text-align: center;
  margin-top: 10px;
}
.payinfo {
  color: red;
  font-size: 0.8rem;
}
.payinfo span {
  color: red;
  font-size: 0.8rem;
}
.importText {
  color: #3f51b5 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.importbody .btnwidth {
  min-width: 180px;
  margin: 15px 0;
}
.steplist {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 !important;
  width: 100%;
}
.w-100 {
  width: 100%;
}
.steplist h3 {
  font-weight: 500;
  margin: 0 20px 0 0;
}
.filename {
  font-weight: 400;
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  float: right;
}
.stepnotes {
  width: calc(100% - 255px);
  padding-right: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #5f6261;
}

.importdefault {
  color: green !important;
}

.importdefault-disable {
  color: grey !important;
}

.import-new-body {
  background-color: #d9dbe4;
}

.import-update-body {
  background-color: #eee;
}

.RaNotification-warning-27 {
  font-size: 14px !important;
}

.MuiSnackbarContent-message {
  font-size: 14px !important;
}

.back-button {
  margin-left: 5px !important;
}
.print-button {
  margin-left: 10px !important;
}

.logoImg {
  max-width: 100px;
}
.menu-side {
  background-color: #1b3faa !important;
  display: flex;
  align-items: center;
  position: inherit;
  color: #fff;
  padding-bottom: 10px;
  font-size: 15px;
}
.menu-side span {
  padding-left: 20px;
}
.metismenu-container .metismenu-container {
  background-color: #1b3faa !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  padding: 0;
}
.metismenu {
  width: 240px;
  position: relative;
  margin-top: 20px;
  right: 20px;
}
.my-menu-link {
  background-color: green;
}
.my-menu {
  background-color: hotpink;
}
.metismenu,
a {
  text-decoration: none;
}
.metismenu-link.active {
  color: #ffe500 !important;
}
.metismenu,
ul {
  list-style-type: none;
}

.metismenu a.active span {
  color: #ffe500;
}

.metismenu .has-arrow::after {
  left: 200px;
  top: 10px !important;
  color: #fff;
  /* display: none; */
}
.list-header {
  margin-bottom: 0px;
  position: relative;
  top: 15px;
}

.backButton {
  margin-right: 10px !important;
  height: 35px;
}

.menu-side:first-of-type {
  padding-bottom: 25px;
}
.mm-show .menu-side {
  padding-bottom: 10px;
}
.mm-active .menu-side {
  padding-bottom: 15px;
}
.mm-show {
  padding-left: 35px;
}
.appImage {
  height: 50px;
  width: auto;
  /* padding-left: 10px; */
}

.table-container {
  height: 65vh;
  overflow-y: scroll;
}
.dashboardDropdown {
  display: flex;
  justify-content: end;
}

.retailer-report .dashboard-flex {
  margin-bottom: 15px;
}
.retailer-report .MuiDataGrid-main {
  background-color: #ffffff;
}
