:root {
    --blue: #0255f0;
    --lt-gray: #cccccc;
    --dk-gray: #767676;
}

.brandList fieldset {
    border: none;
}
.brandList fieldset > label {
    display: inline-block;
    width: 100px;
    font-weight: bold;
    vertical-align: top;
    font-size: 1rem;
    line-height: 28px;
}
 
.brandList fieldset > label::after {
    content: ":";
}
.brandList select,
.brandList details {
    display: inline-block;
    width: 250px;
    background-color: white;
    cursor: pointer;
}
.brandList select,
.brandList .summary {
    border: 1px solid var(--lt-gray);
    border-collapse: collapse;
    border-radius: 4px;
    padding: 4px;
    width: 250px;
    background-color: white;
    cursor: pointer;
}
.brandList details[open] > .brandList summary::marker {
    color: var(--blue);
}
select:focus,
summary:focus,
summary:active {
    box-shadow: 0 0 5px 1px var(--blue);
}
    
.brandList {    border: 1px solid #e2e8f0 !important;  padding: 5px 15px; }
.brandList input[type="text"] {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
    outline: none;
}
.brandList ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-top: 2px;
    border: none;
    box-shadow: none;
    line-height: 24px;
}
.brandList li {
    margin: 0px;
    padding: 0px;
}
.brandList li > label {
    cursor: pointer;
    display: inline-block;
    width: 100%;
}
.brandList li > label input { margin-right: 5px;}
.brandList li > label:hover,
.brandList li > label:has(input:checked) {
    
}